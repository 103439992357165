import React, { useEffect, useRef, useState } from 'react';
import browserIcon from '../assets/icons/browser.svg';
import controlledBudgetIcon from '../assets/icons/budget.svg';
import desktopIcon from '../assets/icons/desktop.svg';
import adviceIcon from '../assets/icons/help.svg';
import methodologyIcon from '../assets/icons/methodology.svg';
import mobileIcon from '../assets/icons/smartphone.svg';
import contrasteUrl from '../assets/image/contraste.svg';
import hakobioUrl from '../assets/image/hakobio.svg';
import actirisUrl from '../assets/image/logo-select.png';
import maVilleSrLeNetUrl from '../assets/image/mavillesurlenet.png';
import recovrUrl from '../assets/image/recovr.svg';
import testEtReussitePermisUrl from '../assets/image/test-et-reussite-permis.png';
import logoUrl from '../assets/logo.svg';
import './index.scss';
import Sidemenu from './sidemenu';

export default function Home() {
    const [isOnTop, setIsOnTop] = useState(true);
    const [sidemenuOpen, setSidemenuOpen] = useState(false);
    const [projectMangementVisible, setProjectMangementVisible] = useState(false);
    const [devVisible, setdevVisible] = useState(false);

    const projectManagementRef = useRef();
    const devRef = useRef();

    const onScroll = (e) => {
        isOnTop ? window.pageYOffset !== 0 && setIsOnTop(false) : window.pageYOffset === 0 && setIsOnTop(true);
        if (projectMangementVisible !== checkVisible(projectManagementRef.current)) {
            setProjectMangementVisible(!projectMangementVisible);
        }
        if (devVisible !== checkVisible(devRef.current)) {
            setdevVisible(!devVisible);
        }
    };
    const openSideMenu = () => {
        setSidemenuOpen(true);
    };
    const closeSidemenu = () => {
        setSidemenuOpen(false);
    };
    const checkVisible = (elm) => {
        var rect = elm.getBoundingClientRect();
        var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
        return !(rect.bottom < 0 || rect.top - viewHeight >= -100);
    };

    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    });
    return (
        <div className={`app ${isOnTop ? 'top' : ''}`}>
            <Sidemenu open={sidemenuOpen} close={closeSidemenu} />
            <div className="sub-navbar" />
            <nav className="navbar">
                <div>
                    <img src={logoUrl} alt="logo" />
                    <h1>Stipo Consulting</h1>
                    <h4>When innovation meets simplicity </h4>
                </div>

                <div>
                    <button onClick={openSideMenu}>Démarrer mon projet</button>
                </div>
            </nav>
            <div className="homescreen">
                <h2>Votre partenaire pour tous vos projets digitaux !</h2>

                <div className="cover" />
            </div>
            <article className="services">
                <h2>Nos services</h2>
                <div ref={projectManagementRef} className={`box-container ${projectMangementVisible ? 'visible' : 'hidden'}`}>
                    <div className="header">
                        <h3>Gestion de projet informatique</h3>
                    </div>
                    <div className="background"></div>
                    <div className="box-body">
                        <div className="box">
                            <div className="box-title">Méthodologie de travail </div>
                            <div className="box-body nowrap">
                                <img src={methodologyIcon} className="icon" alt="methodology" />
                                <span>Méthodologie menant au succès du projet</span>
                            </div>
                        </div>
                        <div className="box">
                            <div className="box-title">Budget </div>
                            <div className="box-body nowrap">
                                <img src={controlledBudgetIcon} className="icon" alt="controlled budget" />
                                Maitrise des coûts.
                            </div>
                        </div>
                        <div className="box">
                            <div className="box-title">Suivi</div>
                            <div className="box-body nowrap">
                                <img src={adviceIcon} className="icon" alt="controlled budget" />
                                Bénéficier de précieux conseils.
                            </div>
                        </div>
                    </div>
                </div>

                <div ref={devRef} className={`box-container left ${devVisible ? 'visible' : 'hidden'}`}>
                    <div className="background "></div>
                    <div className="header">
                        <h3>Développement</h3>
                    </div>
                    <div className="box-body">
                        <div className="box">
                            <div className="box-title">Site web</div>
                            <div className="box-body nowrap">
                                <img className="icon" src={browserIcon} alt="browser icon" />
                                <div>Rapide, Moderne, Responsive.</div>
                            </div>
                        </div>
                        <div className="box">
                            <div className="box-title">Application de bureau</div>
                            <div className="box-body nowrap">
                                <img className="icon" src={desktopIcon} alt="desktop icon" />
                                <div>Adapté à tous les systèmes d'exploitation.</div>
                            </div>
                        </div>
                        <div className="box">
                            <div className="box-title"> Application mobile</div>
                            <div className="box-body nowrap">
                                <img className="icon" src={mobileIcon} alt="mobile icon" />
                                <div>Une seule app pour IOS et Android.</div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="box-container">
                    <div className="header">
                        <h3>Consultance</h3>
                    </div>
                    <div className="background"></div>
                    <div className="box-body">
                        <div className="box">
                            <div className="box-title">Web developement</div>
                            <div className="box-body nowrap">
                                <img src={methodologyIcon} className="icon" alt="methodology" />
                                <span>React, Angular, Vue, Node JS</span>
                            </div>
                        </div>
                        <div className="box">
                            <div className="box-title">C#</div>
                            <div className="box-body nowrap">
                                <img src={controlledBudgetIcon} className="icon" alt="controlled budget" />
                                Maitrise des coûts.
                            </div>
                        </div>
                        <div className="box">
                            <div className="box-title">Scrum Master</div>
                            <div className="box-body nowrap">
                                <img src={adviceIcon} className="icon" alt="controlled budget" />
                                <span>Implémentation de Scrum</span>
                            </div>
                        </div>
                       
                    </div>
                </div> */}
            </article>
            <article className="contributed-projects">
                <h3>Réalisations</h3>

                <div className="contributed-poject-group">
                    <div className="contributed-project-item">
                        <div className="img">
                            <img src={hakobioUrl} alt="hakobio" />
                        </div>
                    </div>
                    <div className="contributed-project-item">
                        <div className="img">
                            <img src={contrasteUrl} alt="contraste" />
                        </div>
                    </div>
                    <div className="contributed-project-item">
                        <div className="img">
                            <img src={actirisUrl} alt="actiris" />
                        </div>
                    </div>
                </div>
                <div className="contributed-poject-group">
                    <div className="contributed-project-item">
                        <div className="img">
                            <img src={maVilleSrLeNetUrl} alt="ma ville sur le net" />
                        </div>
                    </div>
                    <div className="contributed-project-item">
                        <div className="img terp">
                            <img height="100px" src={testEtReussitePermisUrl} alt="test et reussite permis" />
                        </div>
                    </div>
                    <div className="contributed-project-item">
                        <div className="img">
                            <img src={recovrUrl} alt="recovr" />
                        </div>
                    </div>
                </div>
            </article>
        </div>
    );
}
